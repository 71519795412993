import PropTypes from "prop-types"
import React from "react"
import TextChartODD13GHGEmissionsMolecule from "views/molecules/canvases/charts/TextChartODD13GHGEmissionsMolecule"
import TextChartODD13EnergyMixMolecule from "views/molecules/canvases/charts/TextChartODD13EnergyMixMolecule"
import { NODE_TYPE_SITE } from "utils/constants/organization/nodeTypes"
import ScoreLayoutOrganism from "views/organisms/scores/ScoreLayoutOrganism"
import { useAppContext } from "components/AppContext"
import { useTranslation } from "react-i18next"
import DonutChartTemplate from "views/molecules/charts/template/DonutChartTemplate"
import BrokerManufBreakdown from "views/molecules/charts/brocker/BrokerManufBreakdown"
import BrokerModelBreakdown from "views/molecules/charts/brocker/BrokerModelBreakdown"
import TextChartODD13GHGEmissionsAverageMolecule from "views/molecules/canvases/charts/TextChartODD13GHGEmissionsAverageMolecule"
import ScoreChartErrorBoundary from "components/ErrorBoundary/ScoreChartErrorBoundary"
import { EnergyMixKpi } from "views/atoms/charts/kpi/energyMix/EnergyMixKpi"

const Objective13LeaserScoreLayoutOrganism = ({ score }) => {
  const { t } = useTranslation()
  const { currentRootNode } = useAppContext()

  const chart3 = () => {
    const { datasets } = score.lease_odd13_use_vs_fab

    const data = datasets.map((dataset) => dataset.value)
    const labels = datasets.map((dataset) => dataset.label)
    const unit_key = datasets.map((dataset) => dataset.unit_key)

    if (score?.is_consolidated) {
      return (
        <DonutChartTemplate
          title={t(
            "score:score.13.leaserChartEmissionBreakdownPerRegion.title"
          )}
          data={score?.emissions_breakdown?.data}
          labelKeys={score?.emissions_breakdown?.labels}
          unitPath="units:units.emission"
          unitKey={score?.emissions_breakdown?.unit_key}
          helpText={t(
            "score:score.13.chartGHGEmissionsPerEmployee.corporate.help"
          )}
        />
      )
    } else {
      return (
        <DonutChartTemplate
          title={t(
            "score:score.13.leaserChartEmissionBreakdownPerLifecyclePhase.title"
          )}
          data={data}
          labelPath={
            "score:score.13.leaserChartEmissionBreakdownPerLifecyclePhase.labels"
          }
          labelKeys={labels}
          unitPath="units:units.emission"
          unitKey={unit_key}
          helpText={t(
            "score:score.13.leaserChartEmissionBreakdownPerLifecyclePhase.help"
          )}
        />
      )
    }
  }

  return (
    <ScoreLayoutOrganism
      maturity={score.odd13_maturity ?? 0}
      chart1={
        <TextChartODD13GHGEmissionsMolecule
          score={score}
          ghgEmissions={score?.odd13_CO2}
          hidePercentage={!score?.odd13_global_node_parent_score_defined}
        />
      }
      chart2={
        <TextChartODD13EnergyMixMolecule
          energyMixStatus={score?.odd13_energy_mix_status}
          energyMixValue={
            currentRootNode?.methodology === "bilan_carbone"
              ? Number(score?.odd13_energy_mix_value)
              : Number(score?.odd7_market_based_emission_factor)
          }
          country={EnergyMixKpi(score).country}
          based={EnergyMixKpi(score).based}
        />
      }
      chart3={chart3()}
      chart4={<BrokerManufBreakdown score={score?.broker} />}
      chart5={<TextChartODD13GHGEmissionsAverageMolecule score={score} />}
      chart6={<BrokerModelBreakdown score={score?.broker} />}
    />
  )
}

Objective13LeaserScoreLayoutOrganism.propTypes = {
  score: PropTypes.object.isRequired,
}

Objective13LeaserScoreLayoutOrganism.defaultProps = {}

export default Objective13LeaserScoreLayoutOrganism
