import PropTypes from "prop-types"
import React from "react"
import {
  TextChart,
  TextChartMajor,
} from "components/widgets/ScorePage/widgets/TextCharts"
import { useTranslation } from "react-i18next"
import HelpWidgetChartInteractiveMolecule from "views/molecules/interactive/HelpWidgetChartInteractiveMolecule"
import Unit from "views/atoms/reports/Unit"
import { safePercentage } from "utils/safeMath"
import { round } from "services/units/round"
import { convertValueToReadable } from "services/units/valueConversion"
import { CO2_EMISSION_UNITS, T_CO2E } from "services/units/units"

const TextChartODD13DomainGHGEmissionsMolecule = ({
  score,
  ghgEmissions = 0,
}) => {
  const { t } = useTranslation()

  const { value, unit } = convertValueToReadable(
    ghgEmissions,
    T_CO2E,
    CO2_EMISSION_UNITS,
    (value) => round(value)
  )

  const content = {
    kpi: value,
    kpi_unit: <Unit unit={t(`units:units.emission.${unit}`)} />,
    kpi_text: t(`score:score.13.chartGHGEmissions.text`),
    translationPrefix_chartX: `score:score.13.chartGHGEmissions.`,
    reference: round(safePercentage(ghgEmissions, score?.odd13_CO2)),
    reference_unit: "%",
    reference_text: t(`score:score.13.chartGHGEmissions.reference_text`),
  }

  const content_for_display = (
    <TextChartMajor content={content} noSubtextHover />
  )

  return (
    <HelpWidgetChartInteractiveMolecule
      text={t(`score:score.13.chartGHGEmissions.help`)}
    >
      <TextChart content={content_for_display} />
    </HelpWidgetChartInteractiveMolecule>
  )
}

TextChartODD13DomainGHGEmissionsMolecule.propTypes = {
  score: PropTypes.object.isRequired,
  ghgEmissions: PropTypes.string.isRequired,
}

export default TextChartODD13DomainGHGEmissionsMolecule
